<template>
  <div class="legal wf-section">
    <div class="container wide w-container">
      <div class="w-layout-grid grid-11">
        <div
          id="w-node-_60605970-a05f-529b-9b39-522a16dab2b1-0aee1e6c"
          class="div-block-675"
        >
          <h1 class="h1-black">Legal</h1>
          <p class="p-18 grey about">
            Have a question? We most likely have an answer, but if you
            don&#x27;t see what you&#x27;re looking for, feel free to
            <a href="/contact" class="link">contact us</a>.
          </p>
        </div>
      </div>
      <div class="w-layout-grid grid-7">
        <a
          href="/saturn-community-guidelines"
          class="div-block-18 w-inline-block"
        >
          <div class="div-block-17">
            <img
              src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
              loading="lazy"
              width="20"
              alt=""
              class="image-92 upside"
            />
          </div>
          <h3 class="nocaps nomb">Community Guidelines</h3></a
        ><a
          href="/law-enforcement-guidelines"
          class="div-block-18 w-inline-block"
        >
          <div class="div-block-17">
            <img
              src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
              loading="lazy"
              width="20"
              alt=""
              class="image-92 upside"
            />
          </div>
          <h3 class="nocaps nomb">Law Enforcement Guidelines</h3></a
        ><a href="/privacy-policy" class="div-block-18 w-inline-block">
          <div class="div-block-17">
            <img
              src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
              loading="lazy"
              width="20"
              alt=""
              class="image-92 upside"
            />
          </div>
          <h3 class="nocaps nomb">Privacy Policy</h3></a
        ><a href="/terms-of-use" class="div-block-18 w-inline-block">
          <div class="div-block-17">
            <img
              src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
              loading="lazy"
              width="20"
              alt=""
              class="image-92 upside"
            />
          </div>
          <h3 class="nocaps nomb">Terms of Use</h3></a
        >
      </div>
      <a
        href="https://www.connectsafely.org/wp-content/uploads/2019/09/Guide-to-DreamWork.pdf"
        target="_blank"
        class="div-block-18 mt-24 w-inline-block"
        ><h3 class="nocaps nomb connect-s-2">
          ConnectSafely&#x27;s Partners and Educators Guide
        </h3>
        <div class="image-11">
          <p class="paragraph-3">www.connectsafely.org</p>
          <img
            src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f3bb79e3e8735e42e7ee3d0_call_made-24px%201.png"
            width="24"
            alt=""
            class="image-123"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "legal",
};
</script>

<style scoped>
</style>
