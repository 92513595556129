<template>
  <div>
    <div class="hero wf-section">
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7b17b8feb5094655b6abb8_Group%20(12).svg"
        loading="lazy"
        width="137"
        alt=""
        class="image-100"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7aef128fb54d66633f88b3_Group%20(5).svg"
        loading="lazy"
        alt=""
        class="image-101"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7aef12e82213b752aba162_Group%20(6).svg"
        loading="lazy"
        width="104"
        alt=""
        class="image-102"
      />
      <div class="div-block-689">
        <img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7af87ebae34f3c468e1af2_Group%20(10).svg"
          loading="lazy"
          width="192"
          alt=""
          class="image-124"
        />
      </div>
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7aef12435056657d26a094_Group%20(7).svg"
        loading="lazy"
        width="115"
        data-w-id="40acb4db-3062-24c4-ba55-ed81a5e0abe8"
        alt=""
        class="image-103"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7aef12eff6186b80199c3e_Group%20(8).svg"
        loading="lazy"
        data-w-id="96e76a4a-c757-1616-c399-872ec8d8e267"
        alt=""
        class="image-104"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7b1780dfafc30e1efe7fa2_Group%20(11).svg"
        loading="lazy"
        width="137"
        data-w-id="80cb300a-5476-9afd-60ac-d117e76949a8"
        alt=""
        class="image-105"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62cf95b8bd313bf438a685_3.png"
        loading="lazy"
        width="27"
        alt=""
        class="image-106"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62ce8f31de1a6f8d30273b_1.png"
        loading="lazy"
        width="36"
        alt=""
        class="image-107"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f3fe26dc343dcf929a9109b_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%206qq3.png"
        loading="lazy"
        width="36"
        alt=""
        class="image-108"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f3fe26dc343dcf929a9109b_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%206qq3.png"
        loading="lazy"
        width="40"
        data-w-id="ef7fb460-95a5-1be1-94b5-a8869df8382d"
        alt=""
        class="image-109"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62cf95b8bd313bf438a685_3.png"
        loading="lazy"
        width="27"
        data-w-id="628efa7d-16aa-8fcc-3301-901a9db9b6e2"
        alt=""
        class="image-111"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f3fe2c2b21aeaf413771cf6_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%208qq3.png"
        loading="lazy"
        width="44"
        data-w-id="89f568a4-c5bf-d83b-dee8-8cadadade4a3"
        alt=""
        class="image-112"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f8089f2844a9b14c2c533ad_optimized_image_2.png"
        loading="lazy"
        sizes="100vw"
        srcset="
          https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f8089f2844a9b14c2c533ad_optimized_image_2-p-500.png 500w,
          https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f8089f2844a9b14c2c533ad_optimized_image_2.png       717w
        "
        alt=""
        class="image-19"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f3fdfaf264b61197ed17e7a_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%203mmm1.png"
        loading="lazy"
        alt=""
        class="image-20"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62ce8f31de1a6f8d30273b_1.png"
        loading="lazy"
        width="27.5"
        data-w-id="2e0fb88a-aa12-5f48-6348-962f3e60ac94"
        alt=""
        class="image-110"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f808563bd9aa8320e352f92_optimized_blur.png"
        loading="lazy"
        alt=""
        class="image-21"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
        loading="lazy"
        width="20"
        data-w-id="6ad931a1-d949-1176-27fd-87c4eb972af4"
        alt=""
        class="image-126 ab"
      />
      <div class="container center nopad w-container">
        <div class="div-block-21">
          <h1
            data-w-id="4879d27b-7c27-68e8-fe6b-44cff83a309a"
            class="heading-2 m l special"
          >
            The best way <br />To share time <br />With friends
          </h1>
          <h1
            data-w-id="33e8583d-f72b-678f-88fc-2e1af133e300"
            class="heading-2 m l"
          >
            The best way To share <br />time With friends
          </h1>
        </div>
        <div class="div-block-5">
          <a
            data-w-id="4879d27b-7c27-68e8-fe6b-44cff83a30b7"
            style="
              -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
            "
            href="/preview"
            target="_blank"
            class="div-block-22 w-inline-block"
          >
            <div class="div-block-24">
              <div class="div-block-23">
                <div class="text-block-3">Get DreamWork Today</div>
                <img
                  src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f8b59400d072e869dc412e3_slice12.png"
                  loading="lazy"
                  width="20"
                  alt=""
                  class="image-30"
                />
              </div>
            </div> </a
          ><a
            data-w-id="4879d27b-7c27-68e8-fe6b-44cff83a30bf"
            style="
              -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
            "
            href="/preview"
            target="_blank"
            class="div-block-22 _2 w-inline-block"
          >
            <div class="div-block-24">
              <div class="div-block-23 _2">
                <div class="text-block-3">Launch Web App</div>
                <img
                  src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7dd4e49daf85590efcd827_rocket.svg"
                  loading="lazy"
                  width="20"
                  alt=""
                  class="image-30"
                />
              </div>
            </div> </a
          ><a
            data-w-id="116b7996-4073-9f24-1c3c-7478becea4c2"
            style="
              -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
            "
            href="/ambassador"
            class="div-block-22 _2 _3 w-inline-block"
          >
            <div class="div-block-24">
              <div class="div-block-23 _2">
                <div class="text-block-3">Become an ambassador</div>
                <img
                  src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f614feb4604e9cf10cc9c3a_slice7.png"
                  loading="lazy"
                  width="20"
                  alt=""
                  class="image-30"
                />
              </div>
            </div>
          </a>
        </div>
        <p class="paragraph-4">Scroll Down</p>
      </div>
    </div>
    <div
      data-w-id="a4843646-7083-e4b5-d879-65ea916ffad9"
      class="bruh wf-section"
    >
      <div class="container line-path w-container">
        <img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62d06066e6453343fe24bd_4.png"
          loading="lazy"
          width="19"
          data-w-id="7af50ddb-6522-5fcc-e53e-6ea22135d19e"
          alt=""
          class="image-34"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62ce8f31de1a6f8d30273b_1.png"
          loading="lazy"
          width="27.5"
          data-w-id="4d0a5c51-cb03-dd0b-16ee-23ff77f7d9b7"
          alt=""
          class="image-36"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f3fe2c2b21aeaf413771cf6_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%208qq3.png"
          loading="lazy"
          width="44"
          data-w-id="9432e994-9908-6839-c4d1-633f361b8a51"
          alt=""
          class="image-37"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62d0fc85abd01e26bf312d_5.png"
          loading="lazy"
          width="22"
          data-w-id="e9844ca7-3172-e12e-a424-cf0f3a95ec49"
          alt=""
          class="image-41"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62d06066e6453343fe24bd_4.png"
          loading="lazy"
          width="19"
          data-w-id="060ef5c7-ef22-24b8-9435-8bbf098eea01"
          alt=""
          class="image-42"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62ce8f31de1a6f8d30273b_1.png"
          loading="lazy"
          width="27.5"
          data-w-id="872dd764-fe3c-a10a-0548-43ac67faca03"
          alt=""
          class="image-43"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f3fe1ead38aeb1f1b0bc7e5_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%204qq3.png"
          loading="lazy"
          width="58"
          data-w-id="92e596a3-d580-878d-6489-ca4a3950360c"
          alt=""
          class="image-44"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62d06066e6453343fe24bd_4.png"
          loading="lazy"
          width="19"
          data-w-id="8e826c5c-f973-cee7-9a62-dd186b0bee6f"
          alt=""
          class="image-45"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62cf0ac215f8c7495c95f9_2.png"
          loading="lazy"
          width="18.5"
          data-w-id="c672ee37-4886-d9c4-a274-213c7605b162"
          alt=""
          class="image-46"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62cf0ac215f8c7495c95f9_2.png"
          loading="lazy"
          width="36"
          data-w-id="3fff5ead-3445-96f6-a819-b979e2fcf618"
          alt=""
          class="image-48"
        /><img
          src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62cf95b8bd313bf438a685_3.png"
          loading="lazy"
          width="27"
          data-w-id="e3ea13e4-e782-558a-7ec5-06847210984c"
          alt=""
          class="image-49"
        />
        <div
          data-w-id="152b57a0-6893-341d-91d6-0b6ae66fe07e"
          class="w-layout-grid grid-4"
        >
          <div class="div-block-4">
            <div class="div-block-10">
              <img
                src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f776b8a2a50423ec2993921_1.png"
                width="130"
                sizes="(max-width: 479px) 100vw, 40px"
                srcset="
                  https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f776b8a2a50423ec2993921_1-p-500.png  500w,
                  https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f776b8a2a50423ec2993921_1-p-800.png  800w,
                  https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f776b8a2a50423ec2993921_1.png       1212w
                "
                alt=""
                class="image-95"
              />
            </div>
            <div class="div-block-11">
              <h3 class="mb-30">Chat. Built around your day.</h3>
              <p class="_60">
                Talk with your friends, classes, and grade. Want more privacy?
                Take it <br />to a DM.<br />
              </p>
            </div>
          </div>
          <div class="div-block-12">
            <img
              src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7b2b6a62c26e46cdd7fd62_Group%20(10).svg"
              loading="lazy"
              width="179"
              data-w-id="c21b235a-42f0-c5ed-d0e0-ddeaec38b56d"
              alt=""
              class="image-66"
            />
            <div class="div-block-672 _23">
              <div class="image-4 _22 _2 w-embed">
                <!--                        <video id="playyy" width="320" height="690" muted autoplay loop>-->
                <!--                            <source src="https://www.dropbox.com/s/5r7kozqnlur4bjd/chat%20%281%29.mp4?raw=1"-->
                <!--                                    type="video/mp4">-->
                <!--                            Your browser does not support HTML video.-->
                <!--                        </video>-->
              </div>
              <img
                src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7f83cf65bc5095f0f49a5e_101_CHAT.png"
                loading="lazy"
                sizes="(max-width: 479px) 300px, 320px"
                srcset="
                  https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7f83cf65bc5095f0f49a5e_101_CHAT-p-500.png 500w,
                  https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7f83cf65bc5095f0f49a5e_101_CHAT.png       750w
                "
                alt=""
                class="image-4 _22 _25"
              /><img
                src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f6410de693c561dc6a25db8_frame.png"
                loading="lazy"
                alt=""
                class="image-77"
              />
            </div>
            <img
              src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62d1a8b2943d622e8d309c_6.png"
              loading="lazy"
              data-w-id="b0fa5a4e-9c6f-63e9-7ef3-ecc36bd716b6"
              alt=""
              class="image-65"
            />
          </div>
        </div>
        <div class="div-block-674"></div>
        <div
          data-w-id="d6a69b3b-fe3c-e944-d0de-870d5b520f09"
          class="w-layout-grid grid-4"
        >
          <div
            id="w-node-_306ec366-16f2-1c2a-76c4-9ebb743c0c0d-0c922834"
            class="div-block-4 _2"
          >
            <div class="div-block-10-copy _2">
              <img
                src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7d250673e66a2b269277d8_Group%20(14).svg"
                width="82.5"
                alt=""
                class="image-96"
              />
            </div>
            <div class="div-block-11">
              <h3 class="mb-30">It&#x27;s time to burn your planner.</h3>
              <p class="_60">
                And say goodbye to the Notes app. Manage your assignments,
                homework, and tasks in DreamWork.<br />
              </p>
            </div>
          </div>
          <div class="div-block-12 _2">
            <div class="div-block-672 _23 _2">
              <div class="image-4 _22 _2 w-embed"></div>
              <img
                src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7f83d06591e21c1d1f0cec_203_TASKS.png"
                loading="lazy"
                sizes="(max-width: 479px) 300px, 320px"
                srcset="
                  https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7f83d06591e21c1d1f0cec_203_TASKS-p-500.png 500w,
                  https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7f83d06591e21c1d1f0cec_203_TASKS.png       750w
                "
                alt=""
                class="image-4 _22 _25"
              /><img
                src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f6410de693c561dc6a25db8_frame.png"
                loading="lazy"
                alt=""
                class="image-77"
              />
            </div>
            <img
              src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f62d45ba2e1455ce5f114ae_7.png"
              loading="lazy"
              width="362"
              data-w-id="8fd99fe5-832a-575b-47ac-54eb3b09e9f1"
              alt=""
              class="image-67"
            /><img
              src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7af87ebae34f3c468e1af2_Group%20(10).svg"
              loading="lazy"
              width="192"
              data-w-id="fbecb2c4-2072-b480-a927-d98da81ea730"
              alt=""
              class="image-69"
            />
          </div>
        </div>
        <div class="div-block-14">
          <img
            src="@/assets/img/saturn/bottom-mark.png"
            width="96"
            alt=""
            class="image-9"
          />
        </div>
        <div
          data-w-id="b637f4fd-0e20-390b-c9d4-217687bb81e3"
          data-animation-type="lottie"
          data-src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f7b6e9f17bfde68368fee12_dotted_line%20(1).json"
          data-loop="0"
          data-direction="1"
          data-autoplay="0"
          data-is-ix2-target="1"
          data-renderer="svg"
          data-default-duration="1.03436765890371"
          data-duration="0"
          class="image-78"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      const script = document.createElement("script");
      script.src = "/js/webflow.js";
      document.body.append(script);
    });
  },
};
</script>

<style scoped>
</style>
