<template>
  <div
    data-collapse="small"
    data-animation="default"
    data-duration="400"
    data-easing="ease"
    data-easing2="ease"
    role="banner"
    class="navbar w-nav"
  >
    <a href="/" aria-current="page" class="brand w-nav-brand w--current">
      <!-- <div
        data-w-id="efa2815a-5879-e446-fc44-6be30e010f51"
        data-animation-type="lottie"
        data-src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f75eabac9add4a857ae0c87_saturn_logo_animation%20(1).json"
        data-loop="0"
        data-direction="1"
        data-autoplay="0"
        data-is-ix2-target="1"
        data-renderer="svg"
        data-default-duration="1.9333333333333333"
        data-duration="0"
        data-ix2-initial-state="0"
        class="lottie-animation-11"
      ></div> -->
      <img
        src="@/assets/img/saturn/dreamwork.png"
        loading="lazy"
        alt=""
        class="image-logo"
    /></a>
    <nav role="navigation" class="nav-menu w-nav-menu">
      <a href="/about" class="nav-link w-nav-link">About Us</a
      ><a href="/faq" class="nav-link w-nav-link">FAQ</a
      ><a
        data-w-id="d152faeb-e8a5-a1fc-b431-88d2de1f06ad"
        href="https://apply.joinsaturn.com/?utm_source=joinsaturn"
        target="_blank"
        class="div-block w-inline-block"
      >
        <div>
          <div class="text-block">Become an <br />Ambassador</div>
        </div>
        <div class="saturn_web_circle">
          <img
            src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f614feb4604e9cf10cc9c3a_slice7.png"
            width="20"
            alt=""
          />
        </div>
      </a>
    </nav>
    <div class="menu-button w-nav-button">
      <div class="icon w-icon-nav-menu"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header",
};
</script>

<style scoped>
.image-logo {
  display: block;
  height: 36px;
  margin-top: 20px;
}
</style>
