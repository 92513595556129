<template>
  <section>
    <div class="hero about wf-section">
      <div class="container about-new w-container">
        <div class="w-layout-grid grid-11">
          <div
            id="w-node-f73b6633-2821-0edc-634a-7b554f7796d5-8b9f525a"
            class="div-block-675 test"
          >
            <h1 class="h1-black">Welcome to dreamwork.</h1>
            <p class="p-18 grey about">
              DreamWork is a time-based social network. We simplify chaotic high
              school schedules and connect students with the people and
              information that matter most.
            </p>
          </div>
          <div id="w-node-_4af8b0b9-b7bc-3bfe-7139-4138bba818eb-8b9f525a">
            <div
              data-w-id="42bb189f-3a79-7718-0b1a-ab0da048bc1d"
              data-animation-type="lottie"
              data-src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f616b3698a1e549e85edad8_saturn_rocket_animation.json"
              data-loop="1"
              data-direction="1"
              data-autoplay="1"
              data-is-ix2-target="0"
              data-renderer="svg"
              data-default-duration="1"
              data-duration="0"
              class="lottie-animation-10"
            ></div>
          </div>
        </div>
      </div>
      <img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f5a38fb1e35b6547a4d5105_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201mmm1.png"
        loading="lazy"
        sizes="(max-width: 565px) 100vw, 565px"
        srcset="
          https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f5a38fb1e35b6547a4d5105_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201mmm1-p-500.png 500w,
          https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f5a38fb1e35b6547a4d5105_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%201mmm1.png       565w
        "
        alt=""
        class="image-59"
      /><img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f5a38f6e6e57829065efd04_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%203mmm1.png"
        loading="lazy"
        sizes="(max-width: 704px) 100vw, 704px"
        srcset="
          https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f5a38f6e6e57829065efd04_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%203mmm1-p-500.png 500w,
          https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f5a38f6e6e57829065efd04_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%203mmm1.png       704w
        "
        alt=""
        class="image-60"
      /><img
        src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f3fe35d758e86cad1b6b01d_%D0%A0%D0%B5%D1%81%D1%83%D1%80%D1%81%205qq3.png"
        loading="lazy"
        width="25"
        alt=""
        class="image-63"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "aboutUs",
  created() {
    this.$nextTick(() => {
      const script = document.createElement("script");
      script.src = "/js/webflow.js";
      document.body.append(script);
    });
  },
};
</script>

<style scoped>
</style>
