<template>
  <div id="app">
    <a-header></a-header>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <a-footer></a-footer>
  </div>
</template>

<script>
import AFooter from "@/views/saturn/footer.vue";
import AHeader from "@/views/saturn/header.vue";

export default {
  components: {
    AHeader,
    AFooter,
  },
  data() {
    return {
      bottomFixed: false,
      fixedDefault: false
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.judgeBottomFixed();
    },
  },
  created() {
    console.log(1);
    this.judgeBottomFixed();
  },
  methods: {
    judgeBottomFixed() {
      const isDailyPage = this.$route.path.includes("daily");
      const isContactPage = this.$route.path.includes("contact");
      this.bottomFixed = isDailyPage;
      this.fixedDefault = isContactPage;
    }
  }
};
</script>
