<template>
  <section class="main">
    <section class="newsletter-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <h5 class="has-line">Coming Soon</h5>
            <h1>Get Notified when we launch.</h1>
            <form action="#">
              <div class="form-group">
                <input type="email" placeholder="Enter your email">
                <button type="submit">Notify Me</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- News Letter Ends Here -->

    <!-- Only Mobile View Starts Here -->
    <section class="xs-bg d-md-none d-lg-none">
      <img src="@/assets/img/saturn/xs.jpeg" alt="img" class="img-fluid w-100">
    </section>
  </section>
</template>

<script>
export default {
name: "preview"
}
</script>

<style scoped>
  .main {
    background: white;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-right: auto;
    margin-left: auto;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/ -2);
    margin-left: calc(var(--bs-gutter-x)/ -2);
  }
  .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)/ 2);
    padding-left: calc(var(--bs-gutter-x)/ 2);
    margin-top: var(--bs-gutter-y);
  }

  .has-line {
    padding-left: 100px;
    position: relative;
  }
  h5 {
    font-size: 22px;
    font-weight: 500;
  }
  h1, h5 {
    color: #001141;
    text-transform: none;
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  h1 {
    font-size: 94px;
    line-height: 102px;
    font-weight: 700;
    max-width: 820px;
  }

  .form-group {
    margin-top: 30px;
    position: relative;
  }
  .form-group input {
    width: 710px;
    height: 70px;
    border: 1px solid #B1B6C5;
    border-radius: 40px;
    padding-left: 30px;
    -webkit-transition: 0.3s all linear;
    transition: 0.3s all linear;
  }
  .form-group button {
    width: 175px;
    height: 70px;
    border: 1px solid #FF3251;
    border-radius: 40px;
    background-color: #FF3251;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    position: absolute;
    top: 0;
    right: 145px;
  }

  .w-100 {
    width: 100%!important;
  }
  img {
    border: 0;
    outline: 0;
    max-width: 100%;
    vertical-align: middle;
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container, .container-md, .container-sm {
      max-width: 720px;
    }
    .d-md-none {
      display: none!important;
    }
  }

  @media (min-width: 992px) {
    .main {
      background-image: url("~@/assets/img/saturn/bg.jpeg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .container, .container-lg, .container-md, .container-sm {
      max-width: 960px;
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.6666666667%;
    }
    .d-lg-none {
      display: none!important;
    }
    .has-line::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: #333333;
      width: 88px;
      height: 2px;
    }
  }
  @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1140px;
    }
  }

  @media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1320px;
    }
  }
  @media (max-width: 767px) {
    h5 {
      font-size: 16px;
    }
    h1 {
      font-size: 32px;
      line-height: 40px;
    }
  }
  @media (max-width: 420px) {
    h1 {
      text-align: center;
    }
    .newsletter-area {
      padding-top: 150px;
      margin-bottom: 50px;
    }
    .form-group input {
      width: 100%;
      height: 50px;
    }
    .form-group button {
      height: 50px;
      width: 130px;
      right: 0;
    }
    .has-line {
      text-align: center;
      padding: 0;
    }
  }


  @media only screen and (min-width: 420px) and (max-width: 574px) {
    .newsletter-area {
      padding-top: 150px;
      margin-bottom: 50px;
    }
    .form-group input {
      width: 100%;
      height: 50px;
    }
    .form-group button {
      height: 50px;
      width: 130px;
      right: 0;
    }
    .has-line {
      text-align: center;
      padding: 0;
    }
    h1 {
      text-align: center;
    }
  }

  @media (min-width: 575px) and (max-width: 767px) {
    .has-line {
      text-align: center;
      padding: 0;
    }
    h1 {
      text-align: center;
    }
    .form-group input {
      width: 100%;
      height: 50px;
    }
    .form-group button {
      height: 50px;
      width: 130px;
      right: 0;
    }
    .newsletter-area {
      padding-top: 150px;
      margin-bottom: 50px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .has-line {
      text-align: center;
      padding: 0;
    }
    h5 {
      font-size: 18px !important;
    }
    h1 {
      margin: 0 auto;
      max-width: 540px !important;
      text-align: center;
      font-size: 60px !important;
      line-height: 68px !important;
    }
    .form-group input {
      width: 100%;
      height: 55px;
    }
    .form-group button {
      height: 55px;
      right: 0;
    }
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    .form-group input {
      width: 90%;
      height: 60px;
    }
    .form-group button {
      right: 0 !important;
      height: 60px;
    }
    h5 {
      font-size: 20px;
    }
    h1 {
      font-size: 46px;
      line-height: 44px;
    }
  }
  @media (min-width: 1201px) and (max-width: 1399px) {
    .form-group input {
      width: 90%;
    }
    .form-group button {
      right: 0 !important;
    }
  }


</style>
