import Vue from "vue";
import VueRouter from "vue-router";
import privacy from "../views/adlocked/privacy";
import terms from "../views/adlocked/terms";
import home from "../views/saturn/home";
import aboutUs from "@/views/saturn/aboutUs";
import faq from "@/views/saturn/faqs";
import legal from "@/views/saturn/legal";
import preview from "@/views/saturn/preview";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home
  }, {
    path: "/privacy",
    name: "privacy",
    component: privacy
  }, {
    path: "/terms",
    name: "terms",
    component: terms
  }, {
    path: "/about",
    name: "aboutUs",
    component: aboutUs
  }, {
    path: "/faq",
    name: "faq",
    component: faq
  }, {
    path: "/legal",
    name: "legal",
    component: legal
  }, {
    path: "/preview",
    name: "preview",
    component: preview
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
