<template>
  <div>
    <div class="faqs wf-section">
      <div class="container wide w-container">
        <div class="w-layout-grid grid-11">
          <div
            id="w-node-db27493c-5e81-f3ef-da19-a7856c60d2b4-206fc786"
            class="div-block-675"
          >
            <h1 class="h1-black">FAQS</h1>
            <p class="p-18 grey about">
              Have a question? We most likely have an answer, but if you
              don&#x27;t see what you&#x27;re looking for, feel free to contact
              us by clicking <a href="/contact" class="link">here</a>.
            </p>
          </div>
          <div id="w-node-db27493c-5e81-f3ef-da19-a7856c60d2b9-206fc786">
            <div
              data-w-id="db27493c-5e81-f3ef-da19-a7856c60d2ba"
              data-animation-type="lottie"
              data-src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f76788026ed463a88009988_calendar_lottie.json"
              data-loop="1"
              data-direction="1"
              data-autoplay="1"
              data-is-ix2-target="0"
              data-renderer="svg"
              data-default-duration="2.0020019204587935"
              data-duration="0"
              class="lottie-animation-10"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="faq-1 wf-section">
      <div class="container wide w-container">
        <div class="w-layout-grid grid-8">
          <h2 class="heading-6">General<br /></h2>
          <div>
            <div
              data-w-id="38f9c3e4-7e1f-1ea6-f266-b660709c46ec"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">What is DreamWork?</h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="b472d1f4-4398-64d9-ba63-fbab4198ccb1"
                class="paragraph _60 faq"
              >
                DreamWork simplifies chaotic high school schedules by connecting
                students with the people and information that matter most. The
                app helps students keep track of where they need to be and when.
                Students can also share their schedules with their friends to
                see who’s in their classes and when their friends are free.<br />
              </p>
            </div>
            <div
              data-w-id="bb624bc1-3528-4a17-494e-e9945fece242"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>What does the app do? </strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <div
                data-w-id="c93cc6b2-b099-3854-6f6a-cad3c1de52ef"
                class="div-block-708"
              >
                <p class="paragraph _60 faq">
                  DreamWork’s features currently include:<br />
                </p>
                <ul role="list">
                  <li class="list-item">
                    Live View: keeps you up to date on where you need to be
                  </li>
                  <li class="list-item">
                    Schedule Sharing: Keep tabs on your friends and what they’re
                    up to
                  </li>
                  <li class="list-item">
                    Chat: We recently launched messaging, so you can chat with
                    your friends, classes and groups
                  </li>
                  <li class="list-item">
                    Tasks: Manage your to-dos and assignments all in one place
                  </li>
                  <li class="list-item">
                    Lunch Waves: See who is in your lunch and quickly get in
                    touch to make plans to meet up
                  </li>
                  <li class="list-item">
                    Secure Community: Use your school-provided Google or
                    Microsoft account to log-in
                  </li>
                  <li class="list-item">
                    Notifications: Get notified with calendar updates throughout
                    your day, never be late for class again
                  </li>
                </ul>
              </div>
            </div>
            <div
              data-w-id="1ab2f5ca-e1b1-f601-efe1-7b323caad420"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>Why was DreamWork created?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="1ab2f5ca-e1b1-f601-efe1-7b323caad425"
                class="paragraph _60 faq"
              >
                DreamWork was created by students who wanted to make it easier
                to stay organized in high school. University of Pennsylvania
                classmates Dylan Diamond and Max Baron launched DreamWork in the
                fall of 2018.<br /><br />High school schedules are unnecessarily
                complicated. They founded DreamWork to provide students with a
                simple and intuitive solution that helps students navigate the
                complexities of their day-to-day calendar. We keep students
                organized and connected so they can focus on the things that
                matter most.<br />
              </p>
            </div>
            <div
              data-w-id="398f4e3c-176f-3ee3-a2c3-83ca05faf08d"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>Who is behind DreamWork?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="398f4e3c-176f-3ee3-a2c3-83ca05faf093"
                class="paragraph _60 faq"
              >
                DreamWork is based in NYC, and has an amazing team focused on
                improving the way people keep time. We&#x27;re a group of young
                entrepreneurs, and we&#x27;re hiring, check out our job postings
                <a
                  href="https://jobs.lever.co/saturn"
                  target="_blank"
                  class="link"
                  >here</a
                >. We&#x27;re really excited to grow the DreamWork team. <br />
              </p>
            </div>
          </div>
        </div>
        <div class="w-layout-grid grid-8 mt-80">
          <h2 class="heading-5">How to use<br />dreamwork</h2>
          <div>
            <div
              data-w-id="5b4b2c2a-8673-a4d1-3ba6-8db8d68a1896"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>How does DreamWork work?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="5b4b2c2a-8673-a4d1-3ba6-8db8d68a189c"
                class="paragraph _60 faq"
              >
                DreamWork is a platform for students, created by students. It is
                community-specific, meaning that your calendar is tied to the
                classes offered at your school, and cannot be viewed by any
                student outside of your school.<br /><br />To verify you attend
                your school, we check your school email address after you
                download the DreamWork app. Once verified, you can easily login
                and complete our simple onboarding process to add your classes
                from a list of the courses offered at your school.<br /><br />Our
                real-time calendar view helps you monitor your schedule, and
                follow your friends’ schedules as well - with permission, of
                course! Every student can make their schedule private with a
                single tap.<br /><br />As the app continues to grow with your
                support - we’re developing new features to make your days easier
                to manage and to give you new, exciting ways to connect and
                share with other members of your school community.<strong
                  ><br
                /></strong>
              </p>
            </div>
            <div
              data-w-id="12432e47-0bc8-524e-945f-12c33aeacc74"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>Where can I download the app?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="12432e47-0bc8-524e-945f-12c33aeacc7a"
                class="paragraph _60 faq"
              >
                DreamWork is available on iOS and Android, and is free to
                download and use.<br /><br />Download DreamWork
                <a
                  href="https://apps.apple.com/us/app/saturn-school-scheduling/id1454483188"
                  target="_blank"
                  class="link"
                  >here</a
                >.<br /><strong><em></em></strong>
              </p>
            </div>
            <div
              data-w-id="0c33fd4c-3596-9d6b-e9dd-587ec275c1e2"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>How can I get DreamWork in my school?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="0c33fd4c-3596-9d6b-e9dd-587ec275c1e8"
                class="paragraph _60 faq"
              >
                Download DreamWork in the
                <a href="http://meetsaturn.com" target="_blank" class="link-5"
                  >App Store</a
                >. Select your school and select &quot;Request Full
                DreamWork&quot;. We can help you from there to ensure your
                school gets all set up.
              </p>
            </div>
            <div
              data-w-id="974b7cc5-1e5b-208d-cd47-7b111293ed8d"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>What grades does DreamWork support?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="974b7cc5-1e5b-208d-cd47-7b111293ed94"
                class="paragraph _60 faq"
              >
                DreamWork currently supports grades 9-12.<br />
              </p>
            </div>
            <div
              data-w-id="acee5b51-2a25-a6a0-0ccc-6969c50a3f6e"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>Is DreamWork affiliated with my school?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="acee5b51-2a25-a6a0-0ccc-6969c50a3f75"
                class="paragraph _60 faq"
              >
                DreamWork is not affiliated with your school in any way. School
                emails are only used at log-in - to ensure you are placed into
                the right school with your friends and classmates.<br />
              </p>
            </div>
            <div
              data-w-id="f2a0c967-0322-72bd-6dd1-43f63442c05d"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>Are my teachers on DreamWork?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="f2a0c967-0322-72bd-6dd1-43f63442c064"
                class="paragraph _60 faq"
              >
                DreamWork is for students by students - teachers should not be
                on the platform. <br />
              </p>
            </div>
            <div
              data-w-id="7dab4bdf-ad1f-8874-bb44-3d93a48a462a"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>Can I use DreamWork on my computer?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="7dab4bdf-ad1f-8874-bb44-3d93a48a4631"
                class="paragraph _60 faq"
              >
                Yes! DreamWork is available on
                <a href="https://saturn.live" class="link">Web</a>, in case
                phones aren’t allowed in class.<br />
              </p>
            </div>
            <div
              data-w-id="a6aaaa77-4c30-2644-899f-54dfc111f084"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong
                    >Will DreamWork automatically know my schedule, and does it
                    support Remote Learning?</strong
                  >
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="a6aaaa77-4c30-2644-899f-54dfc111f08b"
                class="paragraph _60 faq"
              >
                Yes! As your schedule changes, we keep it updated in the app. We
                provide daily support even amidst constantly changing
                schedules.<br />
              </p>
            </div>
            <div
              data-w-id="fd10995d-9369-fe40-5c4c-4554145da410"
              class="div-block-20"
            >
              <div class="div-block-33">
                <h3 class="heading nocaps nomb medium">
                  <strong>What does DreamWork do with my information?</strong>
                </h3>
                <div
                  style="background-color: rgba(255, 255, 255, 0.1)"
                  class="div-block-19"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f712fcba7133923bc94f648_Vector%20(1).png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-92"
                  />
                </div>
              </div>
              <p
                data-w-id="fd10995d-9369-fe40-5c4c-4554145da417"
                class="paragraph _60 faq"
              >
                All our data privacy information is available in our
                <a href="/privacy-policy" class="link">Privacy Policy</a>.
                DreamWork has very strict data security protocols, and we do not
                sell user data or information to any third party.<br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faqs",
  mounted() {
    this.$nextTick(() => {
      const script = document.createElement("script");
      script.src = "/js/webflow.js";
      document.body.append(script);
    });
  },
};
</script>

<style scoped>
</style>
