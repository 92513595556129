<template>
  <div class="footer wf-section">
    <div class="w-layout-grid grid">
      <!-- <div
        data-w-id="e09a6b8f-eb43-297c-d008-5296bec04d98"
        data-animation-type="lottie"
        data-src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f75eabac9add4a857ae0c87_saturn_logo_animation%20(1).json"
        data-loop="0"
        data-direction="1"
        data-autoplay="0"
        data-is-ix2-target="1"
        data-renderer="svg"
        data-default-duration="1.9333333333333333"
        data-duration="0"
        class="lottie-animation-11"
      ></div> -->
      <img
        src="@/assets/img/saturn/dreamwork.png"
        loading="lazy"
        alt=""
        class="image-logo"
      />
      <div>
        <h4 class="nocaps">
          <a href="/" aria-current="page" class="footer-link w--current"
            >Home</a
          >
        </h4>
        <h4 class="nocaps"><a href="/faq" class="footer-link">FAQ</a></h4>
        <h4 class="nocaps"><a href="/legal" class="footer-link">Legal</a></h4>
        <h4 class="nocaps">
          <a href="/about" class="footer-link">About Us</a>
        </h4>
      </div>
      <div
        id="w-node-_825d6900-f7e6-76b6-0b92-bd1c132a0056-132a0043"
        class="div-block-3"
      >
        <h4 class="nocaps">What is DreamWork?</h4>
        <p class="_60 footer">"这是为了确认我与本站的关系"</p>
        <p class="_60 footer" style="border-top: none;padding-top: 0;">
          DreamWork is a time-based social network. We&#x27;ve built a calendar
          that is beautiful, simple, and social. Then we added your friends.
        </p>
        <div>
          <p class="_60 footer cr inline">©</p>
          <div class="_60 footer cr inline w-embed w-script">
            {{ new Date().getFullYear() }}
          </div>
          <p class="_60 footer cr inline">DreamWork Limited</p>
        </div>
      </div>
      <div
        id="w-node-_825d6900-f7e6-76b6-0b92-bd1c132a0061-132a0043"
        class="div-block-687"
      >
        <h4 class="nocaps">Apps</h4>
        <a
          data-w-id="96c557f4-1188-9447-d377-cb086482d69f"
          href="/preview"
          target="_blank"
          class="div-block-22 footer new w-inline-block"
        >
          <div class="div-block-24">
            <div class="div-block-23 footer">
              <div class="div-block-671">
                <div class="div-block-30">
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f40382c916fd9b2909e1373_rocket.png"
                    loading="lazy"
                    width="20"
                    alt=""
                  />
                </div>
                <div class="text-block-3 nocaps nobold">
                  Launch DreamWork Web
                </div>
              </div>
            </div>
          </div> </a
        ><a
          data-w-id="a3e99536-d3fe-b20e-e099-5af174f16716"
          href="/preview"
          target="_blank"
          class="div-block-22 footer w-inline-block"
        >
          <div class="div-block-24">
            <div class="div-block-23 footer _2">
              <div class="div-block-671">
                <div class="div-block-30">
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f40382621f4e39b0543a6d6_Apple_logo_grey%201.png"
                    loading="lazy"
                    width="19"
                    alt=""
                    class="image-128 appl"
                  />
                </div>
                <div class="text-block-3 nocaps nobold">Download for iOS</div>
              </div>
            </div>
          </div> </a
        ><a
          data-w-id="801b6c7a-abe9-8340-751d-1e4c93e7b8f8"
          href="/preview"
          target="_blank"
          class="div-block-22 footer w-inline-block"
        >
          <div class="div-block-24">
            <div class="div-block-23 footer _3">
              <div class="div-block-671">
                <div class="div-block-30">
                  <img
                    src="https://uploads-ssl.webflow.com/5f3483463d78657c7e922833/5f40382737dae2bad8cbdd7b_layer1.png"
                    loading="lazy"
                    width="20"
                    alt=""
                    class="image-127 and"
                  />
                </div>
                <div class="text-block-3 nocaps nobold">
                  Download for Android
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
};
</script>

<style scoped>
.image-logo {
  display: block;
  /* height: 36px; */
  /* margin-top: 20px; */
}
</style>
